<template>
  <div class="tabs-material-subtitle">
    <Container>
      <h2>Versions et validations</h2>
    </Container>
  </div>

  <template v-if="protocol.bpe">

    <Section class="no-padding">
      <Container>
        <h3>Retranscription</h3>
      </Container>
      <div class="key-values">
        <KeyValue
          table
          label="Nom"
          :value="
            getValidation('RETRANSCRIPTION')?.utilisateur?.prenom &&
            getValidation('RETRANSCRIPTION')?.utilisateur?.nom
              ? getValidation('RETRANSCRIPTION')?.utilisateur?.prenom +
                ' ' +
                getValidation('RETRANSCRIPTION')?.utilisateur?.nom
              : null
          "
        />
        <KeyValue
          table
          label="Date"
          :value="
            getValidation('RETRANSCRIPTION')?.date
              ? new Date(
                  getValidation('RETRANSCRIPTION')?.date
                ).toLocaleString()
              : null
          "
        />
        <KeyValue
          table
          label="Signature (trigramme)"
          :value="getValidation('RETRANSCRIPTION')?.trigramme"
        />
        <KeyValue
          table
          label="Version"
          :value="
            getValidation('RETRANSCRIPTION')
              ? protocol?.version.version
              : 'En attente de validation'
          "
        />
      </div>
    </Section>

    <Section class="no-padding-bottom">
      <Container>
        <h3>Vérification</h3>
      </Container>

      <div class="key-values">
        <KeyValue
          table
          label="Nom"
          :value="
            getValidation('VERIFICATION')?.utilisateur?.prenom &&
            getValidation('VERIFICATION')?.utilisateur?.nom
              ? getValidation('VERIFICATION')?.utilisateur?.prenom +
                ' ' +
                getValidation('VERIFICATION')?.utilisateur?.nom
              : null
          "
        />
        <KeyValue
          table
          label="Date"
          :value="
            getValidation('VERIFICATION')?.date
              ? new Date(getValidation('VERIFICATION')?.date).toLocaleString()
              : null
          "
        />
        <KeyValue
          table
          label="Signature (trigramme)"
          :value="getValidation('VERIFICATION')?.trigramme"
        />
        <KeyValue
          table
          label="Version"
          :value="
            getValidation('VERIFICATION')
              ? protocol?.version.version
              : 'En attente de validation'
          "
        />
      </div>
    </Section>

    <Section class="no-padding-bottom">
      <Container>
        <h3>Validation de l'expérimentateur</h3>
      </Container>

      <div class="key-values">
        <KeyValue
          table
          label="Nom"
          :value="
            getValidation('VALIDATIONEXPERI')?.utilisateur?.prenom &&
            getValidation('VALIDATIONEXPERI')?.utilisateur?.nom
              ? getValidation('VALIDATIONEXPERI')?.utilisateur?.prenom +
                ' ' +
                getValidation('VALIDATIONEXPERI')?.utilisateur?.nom
              : null
          "
        />
        <KeyValue
          table
          label="Date"
          :value="
            getValidation('VALIDATIONEXPERI')?.date
              ? new Date(
                  getValidation('VALIDATIONEXPERI')?.date
                ).toLocaleString()
              : null
          "
        />
        <KeyValue
          table
          label="Signature (trigramme)"
          :value="getValidation('VALIDATIONEXPERI')?.trigramme"
        />
        <KeyValue
          table
          label="Version"
          :value="
            getValidation('VALIDATIONEXPERI')
              ? protocol?.version.version
              : 'En attente de validation'
          "
        />
      </div>
    </Section>

    <Section class="no-padding-bottom">
      <Container>
        <h3>Validation du responsable</h3>
      </Container>

      <div class="key-values">
        <KeyValue
          table
          label="Nom"
          :value="
            getValidation('VALIDATIONRESPONSABLE')?.utilisateur?.prenom &&
            getValidation('VALIDATIONRESPONSABLE')?.utilisateur?.nom
              ? getValidation('VALIDATIONRESPONSABLE')?.utilisateur?.prenom +
                ' ' +
                getValidation('VALIDATIONRESPONSABLE')?.utilisateur?.nom
              : null
          "
        />
        <KeyValue
          table
          label="Date"
          :value="
            getValidation('VALIDATIONRESPONSABLE')?.date
              ? new Date(
                  getValidation('VALIDATIONRESPONSABLE')?.date
                ).toLocaleString()
              : null
          "
        />
        <KeyValue
          table
          label="Signature (trigramme)"
          :value="getValidation('VALIDATIONRESPONSABLE')?.trigramme"
        />
        <KeyValue
          table
          label="Version"
          :value="
            getValidation('VALIDATIONRESPONSABLE')
              ? protocol?.version.version
              : 'En attente de validation'
          "
        />
      </div>
    </Section>

    <Section class="no-padding-bottom" v-if="protocol?.prestation_service">
      <Container>
        <h3>Validation donneur d'ordre</h3>
      </Container>

      <div class="key-values">
        <KeyValue
          table
          label="Validation donneur d'ordre"
          :value="
            getValidation('VALIDATIONDONNEURORDRE')?.utilisateur?.prenom &&
            getValidation('VALIDATIONDONNEURORDRE')?.utilisateur?.nom
              ? getValidation('VALIDATIONDONNEURORDRE')?.utilisateur?.prenom +
                ' ' +
                getValidation('VALIDATIONDONNEURORDRE')?.utilisateur?.nom
              : null
          "
        />
        <KeyValue
          table
          label="Date"
          :value="
            getValidation('VALIDATIONDONNEURORDRE')?.date
              ? new Date(
                  getValidation('VALIDATIONDONNEURORDRE')?.date
                ).toLocaleString()
              : null
          "
        />
        <KeyValue
          table
          label="Signature (trigramme)"
          :value="getValidation('VALIDATIONDONNEURORDRE')?.trigramme"
        />
      </div>
    </Section>

  </template>

  <template v-else>
    <div class="key-values key-values--page-bottom">
      <KeyValue
        v-if="protocol?.statut?.uid !== 'VALIDE' && protocol?.statut?.uid !== 'ENCOURS'"
        table
        label="Validation du protocole"
        value="-"
      />
      <KeyValue
        v-if="protocol?.statut?.uid === 'VALIDE' || protocol?.statut?.uid === 'ENCOURS' "
        table
        label="Validation du protocole"
        value="Validé"
      />
    </div>
  </template>
</template>

<script>
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import KeyValue from '@/components/form/KeyValue.vue'

export default {
  name: 'ProtocolSubItemVersionsAndValidation',

  components: {
    KeyValue,
    Section,
    Container,
  },

  props: {
    pageTitle: {
      type: String,
    },
    protocol: {
      type: Object,
    },
  },

  data() {
    return {
      validations: [],
    }
  },

  mounted() {
    this.getValidations()
  },

  methods: {
    async getProtocol() {
      const response = await this.fetchService.get(
        `protocole/${this.$route.params.id}`,
      )
      const protocol = await response.data
      this.isBpe = protocol

      return protocol
    },

    getValidations() {
      this.fetchService
        .get(`protocole/${this.$route.params.id}`)
        .then((protocol) => {
          this.fetchService
            .get(
              `protocole/${this.$route.params.id}/version/${protocol.data.version.id}/validation`,
            )
            .then((response) => {
              this.validations = response.data
            })
        })
    },

    getValidation(type) {
      if (this.validations) {
        const validation = this.validations.filter((v) => v.type.uid === type)

        if (validation.length > 0) {
          return validation[0]
        }
        return null
      }
      return null
    },
  },
}
</script>

<style scoped>
</style>
